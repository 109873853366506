import React from "react";
import "./rating.styles.scss";

const Rating = ({ rating }) => {
    const star = '⭐'

    return (
        <section className="rating">
            <div className="rating__score">
                {[...Array(parseInt(rating?.score))].map(() => (star))}
            </div>
            <p className="rating__paragraph">
                {rating?.text}
            </p>
        </section>
    )
};

export default Rating;
