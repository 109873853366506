import React from "react";

const Check = () => (
  <svg
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.64194 11.7505L18.4745 0.916748L20.1421 2.58312L7.64194 15.0833L0.14209 7.58341L1.80846 5.91704L7.64194 11.7505Z"
      fill="#86D008"
    />
  </svg>
);

export default Check;
