import React from "react";

import Header from "@layouts/Header";
import TestimonialsSection from "./components/TestimonialsSection/TestimonialsSection";
import TheDigitalBA from "./components/TheDigitalBA/TheDigitalBA";
import Contact from "@sections/Contact";
import DefaultSection from "@sections/DefaultSection";
import ServicesSection from "./components/ServicesSection/ServicesSection";
import ServicesListSection from "./components/ServicesListSection/ServicesListSection";

import "./offering.styles.scss";

import devTimeBackground from '../../images/offering_bg.png'

const OfferingTemplate = ({ data }) => {
  const header = data?.header || {};
  const testimonials =
    (data?.customers && data?.customers.items) || [];
  const theDigital = data?.theDigital || {};
  const ratings = data?.ratings || {};
  const services = data?.services || {};
  const devTime = data?.devTime || {};
  const sectionTwo = data?.sectionTwo || {};
  const sectionThree = data?.sectionThree || {};
  const sectionFour = data?.sectionFour || {};
  const contact = data?.contact || {};

  return (
    <div className="offering">
      <Header {...header} isLong isCentered isWide />
      <TestimonialsSection testimonials={testimonials} />
      <TheDigitalBA theDigital={theDigital} ratings={ratings} />
      <ServicesSection services={services} />

      <section className="devTime">
        <div className="devTime__text-wrapper">
          <div className="devTime__title">{devTime.title}</div>
          <div className="devTime__text">{devTime.text}</div>
        </div>
        <img src={devTimeBackground} className="devTime__img" alt="background" />
      </section>

      <DefaultSection
        className="offering__default-section"
        info={{
          ...sectionTwo,
        }}
        image={sectionTwo?.image}
        isReverse
      />

      <ServicesListSection info={sectionThree}/>

      <DefaultSection
        className="offering__default-section offering__default-section--bottom"
        info={{
          ...sectionFour,
        }}
        image={sectionFour?.image}
        isReverse
      />

      <Contact info={{ ...contact, isWhite: true, }} />
    </div>
  );
};

export default OfferingTemplate;
