import React from "react";
import Layout from "@layouts/Layout";

import OfferingTemplate from "./Offering";

const OfferingPage = ({ data: initialData }) => {
  const data = {
    ...initialData?.markdownRemark?.frontmatter,
    ...initialData?.testimonials?.frontmatter,
  };

  const seo = {
    ...(data?.seo || {}),
    image: data?.seo?.image?.childImageSharp?.fluid?.src,
  };

  return (
    <Layout seo={seo}>
      <OfferingTemplate data={data} />
    </Layout>
  );
};

export default OfferingPage;

export const offeringPageQuery = graphql`
  query OfferingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Offering" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          description
          buttonText
          subTitle
          subTitleBig
        }
        theDigital {
          title
          items{
            title
          }
          text
        }
        ratings {
          score
          text
        }
        services {
          title
          items{
            title
            items{
              desc
            }
          }
        }
        devTime {
          title
          text
        }
        sectionTwo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sectionFour {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sectionThree {
          title
          description
          items{
            text
          }
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contact {
          category
          title
          description
        }
      }
    }
    testimonials: markdownRemark(
      frontmatter: { templateKey: { eq: "Testimonials" } }
    ) {
      frontmatter {
        customers {
          category
          title
          description
          items {
            description
            author {
              fullname
              company
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
