import React from "react";
import CircleItem from "../CircleItem/CircleItem";
import RatingsSection from "../RatingsSection/RatingsSection";
import CircleItem1 from "@components/svg/CircleItem1";
import CircleItem2 from "@components/svg/CircleItem2";
import CircleItem3 from "@components/svg/CircleItem3";
import CircleItem4 from "@components/svg/CircleItem4";

import "./theDigitalBA.styles.scss";

const icons = [CircleItem1, CircleItem2, CircleItem3, CircleItem4];
const TheDigitalBA = ({ theDigital, ratings }) => {
    return (
        <section className="offering__the-digital-ba-section">
            <h2 className="offering__the-digital-ba-section__title">{theDigital.title}</h2>
            <div className="offering__the-digital-ba-section__items-wrapper">
                {theDigital.items.map((item, i) => (
                    <CircleItem key={i} Icon={icons?.[i]} title={item.title}/>                    
                ))}
            </div>
            <p className="offering__the-digital-ba-section__paragraph">
                {theDigital.text}
            </p>
            <RatingsSection ratings={ratings}/>
        </section>
    )
};

export default TheDigitalBA;
