import React from "react";
import "./CircleItem.styles.scss";

const CircleItem = ({ Icon, title }) => {
  return (
    <div className="circleItem">
      <Icon />
      <span className="circleItem__title">{title}</span>
    </div>
  );
};

export default CircleItem;