import ServicesArrow from "@components/svg/ServicesArrow";
import Testimonial from "@components/Testimonial";
import React from "react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./testimonialSection.styles.scss";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

const TestimonialsSection = ({ testimonials }) => {
  const [swiper, updateSwiper] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);

  const params = {
    speed: 800,

    breakpoints: {
      499: {
        spaceBetween: 20,
        slidesPerView: 1,
      },
      800: {
        slidesPerView: 2,
        spaceBetween: 29.5,
      },
      1250: {
        spaceBetween: 29.5,
        slidesPerView: 3,
      },
    },
  };

  const handleChangeActiveKey = (key) => {
    swiper && swiper.slideTo(key);
    setActiveStep(key);
  };
  return (
    <section className="offering__testimonial-section ">
      <div className="container">
        <h2 className="offering__testimonial-title">Trusted By Leading Australian Startups</h2>
        <Swiper
          {...params}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          onSlideChange={(item) => {
            handleChangeActiveKey(item.realIndex);
          }}
          onSwiper={updateSwiper}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <Testimonial {...testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="offering__testimonial-controls">
          <span
            className="offering__testimonial-arrow"
            onClick={() => {
              handleChangeActiveKey(
                activeStep !== 0
                  ? activeStep - 1
                  : testimonials.length,
              );
            }}
          >
            <ServicesArrow />
          </span>
          <span
            className="offering__testimonial-arrow"
            onClick={() => {
              handleChangeActiveKey(
                activeStep !== testimonials.length - 1
                  ? activeStep + 1
                  : 0,
              );
            }}
          >
            <ServicesArrow />
          </span>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
