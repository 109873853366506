import React from "react";
import Image from "@components/Image";
import Check from '../svg/check-red.svg';
import ReactMarkdown from "react-markdown";

import "./servicesListSection.styles.scss";


const ServicesListSection = ({ info }) => {
  return (
    <section className="list-section">
        <div className="list-section__left">
          <div className="list-section__left__title">{info.title}</div>
          <div className="list-section__left__desc"><ReactMarkdown source={info.description} /></div>
          <div className="list-section__left__list">
            {info.items.map((item,i)=>(
              <div className="list-section__left__list__item" key={i}>
                <Check/>
                <span>{item.text}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="list-section__right">
          <Image data={info.image} />
        </div>
      </section>
  )
};

export default ServicesListSection;
