import React from "react";
import Rating from '@components/Rating/Rating';
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PricingArrow from "@components/svg/PricingArrow";

import "./ratingsSection.styles.scss";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

const RatingsSection = ({ ratings }) => {
  const [swiper, updateSwiper] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);

  const params = {
    speed: 800,
    centeredSlides: true,
    spaceBetween: 20,
    slidesPerView: 1,
    autoHeight: true,
  };

  const handleChangeActiveKey = (key) => {
    swiper && swiper.slideTo(key);
    setActiveStep(key);
  };
  return (
    <>
      <section className="ratingsSection">
        {ratings.map((item, i) => (
          <Rating key={i} rating={item} />
        ))}
      </section>
      <section className="ratingsSection--mobile">
        <div className="ratingsSection--mobile__swiper-wrapper">
          <Swiper
            {...params}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            onSlideChange={(item) => {
              handleChangeActiveKey(item.realIndex);
            }}
            onSwiper={updateSwiper}
          >
            {ratings.map((item, i) => (
              <SwiperSlide key={i}>
                <Rating rating={item} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="ratingsSection__swiper-controls">
            <span
              className="ratingsSection__swiper-arrow"
              onClick={() => {
                handleChangeActiveKey(
                  activeStep !== 0
                    ? activeStep - 1
                    : ratings.length,
                );
              }}
            >
              <PricingArrow />
            </span>
            <span
              className="ratingsSection__swiper-arrow"
              onClick={() => {
                handleChangeActiveKey(
                  activeStep !== ratings.length - 1
                    ? activeStep + 1
                    : 0,
                );
              }}
            >
              <PricingArrow />
            </span>
          </div>
        </div>
      </section>

    </>
  )
};

export default RatingsSection;
