import React from "react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ServicesArrow from "@components/svg/ServicesArrow";

import Check from "../svg/check.svg";

import "./servicesSection.styles.scss";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

const ServicesSection = ({ services }) => {
  const [swiper, updateSwiper] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);

  const params = {
    speed: 800,
    centeredSlides: true,
    spaceBetween: 20,
    slidesPerView: 1,
    autoHeight: true,
  };
  const handleChangeActiveKey = (key) => {
    swiper && swiper.slideTo(key);
    setActiveStep(key);
  };

  return (
    <section className="servicesSection">
      <h2 className="servicesSection__title">{services.title}</h2>
      <div className="servicesSection__list-wrapper">
        {services.items.map((item, i) => (
          <div className="servicesSection__list-item" key={i}>
            <div className="servicesSection__list-item__title">
              {item.title}
            </div>
            <div className="servicesSection__list-item__content">
              {item.items.map((text, idx) => (
                <div className="servicesSection__list-item__content__row" key={idx}>
                  <Check />
                  <span>{text.desc}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="servicesSection__list-wrapper--mobile">
        <Swiper
          {...params}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          onSlideChange={(item) => {
            handleChangeActiveKey(item.realIndex);
          }}
          onSwiper={updateSwiper}
        >
          {services.items.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="servicesSection__list-item" key={i}>
                <div className="servicesSection__list-item__title">
                  {item.title}
                </div>
                <div className="servicesSection__list-item__content">
                  {item.items.map((text, idx) => (
                    <div className="servicesSection__list-item__content__row" key={idx}>
                      <Check />
                      <span>{text.desc}</span>
                    </div>
                  ))}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="servicesSection__swiper-controls">
            <span
              className="servicesSection__swiper-arrow"
              onClick={() => {
                handleChangeActiveKey(
                  activeStep !== 0
                    ? activeStep - 1
                    : services.items.length,
                );
              }}
            >
              <ServicesArrow />
            </span>
            <span
              className="servicesSection__swiper-arrow"
              onClick={() => {
                handleChangeActiveKey(
                  activeStep !== services.items.length - 1
                    ? activeStep + 1
                    : 0,
                );
              }}
            >
              <ServicesArrow />
            </span>
          </div>
      </div>
    </section>
  )
};

export default ServicesSection;
